<template>
  <div class="v-form v-form__check check">
    <h1>Ввод вручную</h1>

    <v-alert :value="isError" elevation="2" dense type="error" dark transition="scale-transition"
      >Данный чек <br />
      уже участвует в акции</v-alert
    >

    <div class="check__wrap manual_check">
      <div class="check-container-input">
        <v-text-field
          outlined
          required
          placeholder="0000.00"
          type="number"
          label="Сумма"
          clearable
          class="check-input"
          :class="{ errorDate: !valid_manual_sum }"
          v-model="manual_sum"
          dense
        ></v-text-field>
      </div>

      <div class="check-container-input">
        <v-text-field
          outlined
          label="Дата и время"
          placeholder="ДД.ММ.ГГГГ ЧЧ:ММ"
          required
          clearable
          v-model="manual_date"
          class="check-input"
          :class="{ errorDate: !valid_manual_date }"
          dense
          v-mask="'##.##.#### ##:##'"
        ></v-text-field>
      </div>

      <div class="check-container-input">
        <v-text-field
          outlined
          label="ФН №"
          required
          clearable
          class="check-input"
          :class="{ errorDate: !valid_manual_fn }"
          v-model="manual_fn"
          dense
          placeholder="0000000000000000"
          v-mask="'################'"
        ></v-text-field>
      </div>

      <div class="check-container-input">
        <v-text-field
          outlined
          required
          label="ФД №"
          clearable
          class="check-input"
          :class="{ errorDate: !valid_manual_fd }"
          v-model="manual_fd"
          dense
          placeholder="0000000000"
          v-mask="'##########'"
        ></v-text-field>
      </div>

      <div class="check-container-input">
        <v-text-field
          outlined
          required
          label="ФП №"
          clearable
          class="check-input"
          :class="{ errorDate: !valid_manual_fp }"
          v-model="manual_fp"
          dense
          placeholder="0000000000"
          v-mask="'##########'"
        ></v-text-field>
      </div>
    </div>
    <v-btn depressed :loading="isLoading" color="primary" class="next-btn check-btn" @click="manual_send" block>
      <div class="btn-content-container">Отправить</div></v-btn
    >
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { mapActions } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: false,
      valid_manual_date: false,
      valid_manual_sum: false,
      valid_manual_fn: false,
      valid_manual_fd: false,
      valid_manual_fp: false,
      manual_sum: "",
      manual_date: "",
      manual_fn: "",
      manual_fd: "",
      manual_fp: "",
      user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
      isError: false,
    };
  },
  methods: {
    async manual_send() {
      try {
        this.isLoading = true;
        this.isError = false;

        if (!this.valid_manual_date || !this.manual_date || !this.manual_sum || !this.manual_fn || !this.manual_fd || !this.manual_fp) {
          this.isLoading = false;
          throw new Error("All fields require");
        }

        const splitDate = this.manual_date.split(" ");
        const convertDate = splitDate[0] + "T" + splitDate[1];

        const date = moment(convertDate.trim(), "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss");

        const { data } = await axios.post(`${process.env.VUE_APP_QR}`, {
          account_id: this.user_id,
          time: date,
          summary: this.manual_sum,
          fn: this.manual_fn,
          fd: this.manual_fd,
          fp: this.manual_fp,
          type: "manual",
          operation: 1,
        });

        if (data.data === "qrcode already exist") {
          this.isError = true;
        } else this.$router.push({ name: "LcSuccess" }).catch(() => {});
      } catch (error) {
        console.error(error.message);
      } finally {
        this.isLoading = false;
      }
    },

    ...mapActions({
      getUserNotification: "getUserNotification",
      getUserData: "getUserData",
    }),
  },

  watch: {
    manual_sum() {
      return !this.manual_sum ? (this.valid_manual_sum = false) : (this.valid_manual_sum = true);
    },
    manual_date() {
      const currentDate = moment().local();

      if (!this.manual_date) return (this.valid_manual_date = false);
      if (this.manual_date) {
        const splitDate = this.manual_date.split(" ");
        const convertDate = splitDate[0] + "T" + splitDate[1];
        const validDate = /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.20\d{2}T([01]\d|2[0-3]):([0-5]\d)$/gm.test(convertDate);
        const formatData = moment(convertDate, "DD.MM.YYYY HH:mm");

        if (validDate) return formatData < currentDate ? (this.valid_manual_date = true) : (this.valid_manual_date = false);
      }
    },
    manual_fn() {
      return !this.manual_fn ? (this.valid_manual_fn = false) : (this.valid_manual_fn = true);
    },
    manual_fd() {
      return !this.manual_fd ? (this.valid_manual_fd = false) : (this.valid_manual_fd = true);
    },
    manual_fp() {
      return !this.manual_fp ? (this.valid_manual_fp = false) : (this.valid_manual_fp = true);
    },
  },
};
</script>

<style scoped>
.v-form.v-form__check.check {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.005em;
  gap: 10;
  color: #1a1a1a;
}
.v-form.check h1 {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;

  text-align: center;
  letter-spacing: -0.005em;
  margin-bottom: 50px;
  color: #1a1a1a;
}
.check__wrap {
  gap: 0;
}
.check-container-input {
  margin-bottom: 5px;
}
.check__subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: -0.015em;

  color: #1a1a1a;
  margin-bottom: 4px !important;
}
.check-btn {
  margin-top: 0;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
  .v-form {
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
    padding: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .v-form.check h1 {
    font: 700 40px "PT Sans";
    line-height: 110%; /* 44px */
    letter-spacing: -0.2px;
    margin-bottom: 0;
  }

  .check__wrap {
    width: 100%;
  }
}
</style>
