export default {
  install(Vue) {
    Vue.prototype.$reachGoal = function (goal, params = {}) {
      if (window.ym) {
        window.ym(process.env.VUE_APP_YANDEX_METRIC_CONTAINER_ID, "reachGoal", goal, params);
        console.log(goal);
      } else {
        console.warn("Яндекс.Метрика не инициализирована");
      }
    };
  },
};
